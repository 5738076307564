const environment = process.env.ENV || 'dev'

const bosunEnvironmentMapper = {
  dev: 'dev',
  staging: 'stg',
  production: 'prd',
}

const bosunEnvironment = bosunEnvironmentMapper[environment]

const config = {
  MULTIPLE_SESSIONS: process.env.MULTIPLE_SESSIONS === 'true',
  STUB_PORT: process.env.STUB_PORT || '8081',
  STUB_API_PATH: '/stub-api',
  DASHBOARD_PATH: '/dashboard',
  DYSON_STUB_COOKIE_NAME: 'ui_global_dyson_session',
  CMS_CONTENT_ENDPOINT_ARGOS:
    process.env.CMS_CONTENT_SERVICE_ENDPOINT || 'https://api.argos.co.uk/api/cms-argos-content',
  CMS_CONTENT_ENDPOINT_HABITAT:
    process.env.CMS_CONTENT_SERVICE_ENDPOINT || 'https://api.argos.co.uk/api/cms-habitat-content',
  EXTERNAL_CMS_CONTENT_SERVICE_KEY_ARGOS: process.env.EXTERNAL_CMS_CONTENT_SERVICE_KEY_ARGOS || '',
  EXTERNAL_CMS_CONTENT_SERVICE_KEY_HABITAT: process.env.EXTERNAL_CMS_CONTENT_SERVICE_KEY_HABITAT || '',
  PRODUCT_GATEWAY_SERVICE_ENDPOINT:
    process.env.PRODUCT_GATEWAY_SERVICE_ENDPOINT || environment === 'production'
      ? `https://pdp-product-gateway.service.eu-west-1.${environment}.deveng.systems`
      : `https://argos-pdp-backend-product-gateway.int.${bosunEnvironment}.jspaas.uk`,
  PRODUCT_GATEWAY_SERVICE_KEY: process.env.PRODUCT_GATEWAY_SERVICE_KEY || '',
}

module.exports = config
