import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'
import { ProductCard, ProductCardSkeleton } from '@sainsburys-tech/bolt'
import { pushEventAndTrack, pushRecommendation } from '../../helpers/TaggingSerializer'
import { promoCardLayoutBreakdown, getRandomString } from '../../helpers/TaggingHelper'

import { breakpointPx } from '../../helpers/ContentHelper'
import * as Styled from './PromotionPanel.styles'

class PromotionPanel extends Component {
  constructor(props) {
    super(props)
    this.trackSlider = this.trackSlider.bind(this)
    this.renderSlider = this.renderSlider.bind(this)
    this.updateDigitalDataObject = this.updateDigitalDataObject.bind(this)
    this.impressionsFired = []
    this.citrusImpressionsFired = []
    this.citrusBeaconUrl =
      process.env.CITRUS_ENV === 'production'
        ? 'https://integration.argos.citrusad.com/'
        : 'https://staging-integration.citrusad.com/'
    this.state = {
      hasViewed: false,
    }
  }

  componentDidMount() {
    this.fireBeacon()
  }

  getShortTitle(title) {
    return title.split(' ').reduce((shortTitle, word) => {
      return shortTitle.concat(word.charAt(0).toUpperCase())
    }, '')
  }

  updateDigitalDataObject(action, currentSlide = 1) {
    const { title, source, data, tracking } = this.props
    if (global.window && !data.error && !data.loading && tracking[action]) {
      const recommendationInfo = {
        title,
        source,
        mvts: tracking.mvts,
        placement: source,
        innerPlacement: `P${currentSlide}`,
        pageCategory: tracking.pageCategory,
        sectionTitle: this.getShortTitle(title),
        ...this.displayedList(currentSlide),
      }
      const eventTrackingInfo = {
        eventInfo: { ...tracking[action], eventRef: getRandomString() },
        attributes: {
          recommendations: recommendationInfo,
        },
      }

      pushRecommendation(recommendationInfo)
      pushEventAndTrack(eventTrackingInfo)
    }
  }

  getSlideItems(slide) {
    const { data } = this.props
    const itemCount = this.displayedList(slide).cardDisplay
    return data.products.slice(...itemCount)
  }

  fireBeacon() {
    const { data } = this.props
    if (!data.pageBeacon) return
    const currentSlide = 1
    const items = this.getSlideItems(currentSlide)
    items.forEach((item) => {
      if (item.attributes.tracking) {
        const img = document.createElement('img')
        img.src = item.attributes.tracking.ImpBeacon
        img.width = 1
        img.height = 1
        img.style.visibility = 'hidden'
        img.className = 'sr-hidden hl-pixel'
        img.alt = 'hook logic component tracking pixel'

        document.body.appendChild(img)
      }
    })
  }

  async sendBeacon(url) {
    if (navigator.sendBeacon) {
      navigator.sendBeacon(url)
    } else {
      await fetch(url)
    }
  }

  displayedList(currentSlide) {
    const { data } = this.props
    if (!data.products) return null
    const definedQueries = [
      `(min-width: ${breakpointPx.LARGE}px)`,
      `(min-width: ${breakpointPx.MEDIUM}px)`,
      `(min-width: ${breakpointPx.SMALL}px)`,
      `(min-width: ${breakpointPx.MOBILE}px)`,
    ]

    const matchingQuery = definedQueries.findIndex((item) => window.matchMedia(item).matches)
    const viewportCount = matchingQuery === 0 ? 4 : matchingQuery === 1 ? 3 : 2
    const validProducts = data.products.filter((validProduct) => validProduct)
    const fullList = validProducts.map((product) => product.id)

    const cardDisplay = promoCardLayoutBreakdown[viewportCount][currentSlide]
    const firstActiveCard = cardDisplay[0]
    const lastActiveCard = cardDisplay[1] > fullList.length ? fullList.length : cardDisplay[1]

    return {
      displayedList: fullList.slice(firstActiveCard, lastActiveCard),
      cardDisplay,
      fullList,
    }
  }

  citrusProductImpressions(currentSlide = 1, items = []) {
    if (!items.length) {
      items = this.getSlideItems(currentSlide)
    }
    items.forEach((item) => {
      if (item?.attributes?.tracking?.id?.includes('display_') && this.citrusImpressionsFired.indexOf(item.id) < 0) {
        this.citrusImpressionsFired.push(item.id)
        this.sendBeacon(`${this.citrusBeaconUrl}v1/resource/first-i/${item.attributes.tracking.id}`)
      }
    })
  }

  trackSlider(currentSlide) {
    const { citrusEnabled, data } = this.props
    const { pageBeacon } = data
    this.updateDigitalDataObject('onSlideChange', currentSlide)

    const items = this.getSlideItems(currentSlide)

    citrusEnabled && this.citrusProductImpressions(currentSlide, items)

    if (!pageBeacon) return
    items.forEach((item) => {
      if (this.impressionsFired.indexOf(item.id) < 0 && item?.attributes?.tracking) {
        this.impressionsFired.push(item.id)
        const img = document.createElement('img')
        img.src = item.attributes.tracking.ImpBeacon
        img.width = 1
        img.height = 1
        img.style.visibility = 'hidden'
        img.className = 'sr-hidden hl-pixel'
        document.body.appendChild(img)
      }
    })
  }

  renderSlider(products) {
    const { citrusEnabled, section, title, source } = this.props
    const renderProductCard = (product, index) => {
      let productUrl = `/product/${product.id}`
      const isTuProduct = product.id.indexOf('TU') > -1
      const calculatePage = (i) => {
        const pages = 4
        return Math.ceil((i + 1) / pages)
      }

      const shortTitle = this.getShortTitle(title)

      const page = `p${calculatePage(index)}`

      if (product.attributes?.tracking) {
        productUrl = product.attributes.tracking.ProductPage
      } else {
        const params = `?rec=HP:${section}:${page}:${shortTitle}:${source}:${product.id}`
        productUrl = `/product/${product.id + params}`
      }
      const price = product.included.find((included) => included.type === 'prices')?.attributes
      const variants = product.included.find((included) => included.type === 'variants')?.attributes?.variants
      const displayId = product.attributes?.tracking?.id
      return (
        <ProductCard
          partNumber={product.id}
          productLinkOverride={productUrl}
          name={product.attributes.name}
          price={price?.now}
          hasMultiplePrices={variants?.length > 0}
          displaySpecialOfferText
          displayWasPrice={!isTuProduct}
          specialOfferText={price?.flashText}
          wasPrice={price?.was}
          onClick={async () =>
            displayId.includes('display_') &&
            citrusEnabled &&
            this.sendBeacon(`${this.citrusBeaconUrl}v1/resource/second-c/${displayId}`)
          }
          isBorderless
        />
      )
    }

    const slideProps = {
      itemsPerSlideXs: 2,
      itemsPerSlideSm: 2,
      itemsPerSlideMd: 3,
      itemsPerSlideLg: 4,
      isInfiniteCycle: true,
      showChevrons: true,
      slideClass: 'promotion-panel-slide',
      onSlideChanged: this.trackSlider,
      onSliderLoaded: () => {
        this.updateDigitalDataObject('onLoad')
        citrusEnabled && this.citrusProductImpressions()
      },
    }
    const productSlides = products.map((product, index) => (
      <div key={index} className='xs-12 sm-6 md-4 lg-3'>
        {renderProductCard(product, index)}
      </div>
    ))
    if (products.length !== productSlides.length) return null
    return <Styled.PromotionSlider {...slideProps} items={productSlides} />
  }

  onSliderView = () => {
    this.setState({
      hasViewed: true,
    })
    this.updateDigitalDataObject('onView')
  }

  render() {
    const { data, title, dataTest } = this.props
    const { hasViewed } = this.state
    const { loading, products, error, pageBeacon } = data

    if (error) return null
    const validProducts = products && products.filter((validProduct) => validProduct)
    const showProducts = !loading && validProducts.length > 0

    if (!loading && validProducts.length === 0) {
      return null
    }

    return (
      <Styled.PromotionSlider as='div' data-test={dataTest}>
        <Styled.Heading className='text-center'>{title}</Styled.Heading>
        {loading && (
          <Styled.SkeletonWrapper className='xs-row'>
            {new Array(4).fill(undefined).map((_, index) => (
              <div key={`${index}-skeleton`} className='xs-12 sm-6 md-4 lg-3'>
                <ProductCardSkeleton isPortrait />
              </div>
            ))}
          </Styled.SkeletonWrapper>
        )}
        {showProducts && (
          <InView onChange={() => !hasViewed && this.onSliderView()} triggerOnce>
            <div className='xs-row xs-12--none'>{this.renderSlider(validProducts)}</div>
          </InView>
        )}
        {pageBeacon !== undefined && (
          <img
            src={pageBeacon}
            width='1'
            height='1'
            style={{ visibility: 'hidden' }}
            className='sr-hidden hl-pixel-global'
            alt='hook logic component tracking pixel'
          />
        )}
      </Styled.PromotionSlider>
    )
  }
}

PromotionPanel.propTypes = {
  citrusEnabled: PropTypes.bool,
  dataTest: PropTypes.string,
  data: PropTypes.shape({
    error: PropTypes.bool,
    loading: PropTypes.bool,
    pageBeacon: PropTypes.string,
    products: PropTypes.shape([]),
    recommendations: PropTypes.shape([]),
  }).isRequired,
  section: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tracking: PropTypes.shape({
    mvts: PropTypes.string,
    pageCategory: PropTypes.string,
    onLoad: PropTypes.shape({
      eventAction: PropTypes.string,
      eventName: PropTypes.string,
    }),
    onView: PropTypes.shape({
      eventAction: PropTypes.string,
      eventName: PropTypes.string,
    }),
    onSlideChange: PropTypes.shape({
      eventAction: PropTypes.string,
      eventName: PropTypes.string,
    }),
  }),
}

PromotionPanel.defaultProps = {
  citrusEnabled: false,
  dataTest: 'promotion-panel',
  tracking: {},
}

export default PromotionPanel
