import { matchPath } from 'react-router-dom'
// @TODO reminder to delete homepage redux code when removing old homepage code
// import { loadHomepageData } from './actions/homepageAppActions'
import { loadCmsData } from './actions/cmsAppActions'
import { fallbackMeta } from './helpers/ContentHelper'

export const homepageRoute = {
  path: '/',
  exact: true,
  fetch: (path, location, variantTests) => {
    return loadCmsData({ location: location.url, variantTests })
  },
  analytics: {
    pageName: 'HOMEPAGE:',
    loadEvent: 'homepage_loaded',
  },
}

export const cmsPageRoute = {
  path: '/(events|features|brands|help|inspiration|shop-by-room|stores)/:page?',
  fetch: (path, location, variantTests) => loadCmsData({ location: location.url, variantTests }),
  analytics: {
    pageName: 'CMS:',
    loadEvent: 'cms_loaded',
  },
}

export const reviewsRoute = {
  path: '/reviews',
  analytics: {
    pageName: 'Reviews:',
    loadEvent: 'reviews_loaded',
  },
  pageTitle: 'Argos Reviews',
  pageDescription: '',
}

export const errorPageRoute = {
  path: '/errorpage',
  analytics: {
    pageName: 'Error:',
    loadEvent: 'error_loaded',
  },
  pageTitle: 'Error Page',
  pageDescription: '',
}

export const roomCreatorRoute = {
  path: '/room-creator',
  analytics: {
    pageName: 'Room Creator:',
    loadEvent: 'room_creator_loaded',
  },
  pageTitle: 'Room Creator',
  pageDescription: '',
}

export const getTheLookRoute = {
  path: '/get-the-look',
  analytics: {
    pageName: 'Get the look:',
    loadEvent: 'get_the_look_loaded',
  },
  pageTitle: 'Get the look',
  pageDescription: '',
}

export const routes = [reviewsRoute, errorPageRoute, cmsPageRoute, homepageRoute, roomCreatorRoute, getTheLookRoute]

// Returns the `route` config for a given path
export const getRoute = (path) => routes.find((route) => matchPath(path, route))

// Triggers the action associated with a route. Used for SSR to
// wait for actions to complete before rendering the page.
export const processRoutes = (store, req, variantTests) => {
  routes.some((route) => {
    const match = matchPath(req.path, route)
    if (match && typeof route.fetch === 'function') {
      store.dispatch(route.fetch(match, req, variantTests))
    }
    return match
  })
}

// Returns the pageTitle for a given route
export const getMeta = (path) => ({
  title: (getRoute(path) && getRoute(path).pageTitle) || fallbackMeta.title,
  description: (getRoute(path) && getRoute(path).pageDescription) || fallbackMeta.description,
})
