import React from 'react'
import Loadable from 'react-loadable'
import PageSkeleton from '../components/Skeletons/PageSkeleton'

export const ErrorPageContainer = Loadable({
  loader: () => import(/* webpackChunkName: "errorpage" */ './ErrorPageContainer/ErrorPageContainer'),
  loading() {
    return <PageSkeleton page='errorpage' />
  },
  modules: ['errorpage'],
})

export const ReviewsContainer = Loadable({
  loader: () => import(/* webpackChunkName: "reviews" */ './ReviewsContainer/ReviewsContainer'),
  loading() {
    return <PageSkeleton page='reviews' />
  },
  modules: ['reviews'],
})

export const CmsPageContainer = Loadable({
  loader: () => import(/* webpackChunkName: "cmspage" */ './CmsPageContainer/CmsPageContainer'),
  loading() {
    return <PageSkeleton page='cms' />
  },
  modules: ['cmspage'],
})

export const HullabalookContainer = Loadable({
  loader: () => import(/* webpackChunkName: "hullabalook" */ './HullabalookContainer/HullabalookContainer'),
  loading() {
    return <PageSkeleton page='hullabalook' />
  },
  modules: ['hullabalook'],
})
