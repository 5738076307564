import serialize from 'serialize-javascript'
import { ensureStructure } from './TaggingHelper'
import getCookie from './getCookie'
import {
  isHomepagePath,
  isRoomCreatorPath,
  isGetTheLookPath,
  isMagnoliaApp,
  isReviewsPath,
  isReviewsArgosCard,
} from './pathCheck'

class TaggingSerializer {
  constructor(location = '', userInfo = {}, data = {}, clickOrigin = '') {
    this.userInfo = userInfo
    this.location = location
    this.data = data
    this.clickOrigin = clickOrigin
  }

  getDataObject() {
    return {
      page: this.getPageObject(),
      user: this.getUserObject(),
    }
  }

  getPageObject() {
    const siteSection = this.getSiteSection()
    const pageName = this.getPageName()
    const attributes = { ...this.getAttributes(), ...(this.clickOrigin && { clickorigin: this.clickOrigin }) }
    const templateTitle = this.getTemplateTitle()
    return {
      pageInfo: {
        attributes: { templateTitle, sprintReference: '1.0' },
        siteSection: `ar:${siteSection}:`,
        pageName: pageName ? `ar:${siteSection}:${pageName}:` : `ar:${siteSection}:`,
      },
      attributes,
      category: {
        pageType: siteSection,
      },
    }
  }

  getSiteSection() {
    if (!this.location || !this.location.length) return null
    const path = this.location
    if (isHomepagePath(path)) return 'home'
    if (isMagnoliaApp(path)) return this.location.split('/')[1]
    if (isReviewsPath(path)) return 'cgc'
    if (isRoomCreatorPath(path)) return 'room-creator'
    if (isGetTheLookPath(path)) return 'get-the-look'
    return 'error-page'
  }

  getPageName() {
    if (!this.location || !this.location.length) return null
    let path = this.location

    const hasInvalidLocationPath = path.substr(-1) === '/'
    if (hasInvalidLocationPath) path = path.slice(0, -1)

    if (isMagnoliaApp(path)) {
      const noPageName = path.split('/').length <= 2
      if (noPageName) return null
      const validPageName = path.split('/').pop()
      return validPageName
    }
    if (isReviewsPath(path) && !isReviewsArgosCard(path)) return 'askQuestion'
    if (isReviewsArgosCard(path)) return 'reviews_argos_card'
    // if (isHomepagePath(path)) return null
    return null
  }

  getAttributes() {
    const path = this.location
    if (isHomepagePath(path))
      return {
        channel: 'uk:desktop',
        platform: 'react_home',
        recommendations: [],
      }
    if (isMagnoliaApp(path)) {
      if (!this.data.data) return {}
      const templateInfo = {
        platform: 'react_magnolia',
        templateId: this.data.data[0].id,
        templateDesc: this.data.data[0].desc,
      }
      return templateInfo
    }
    if (isReviewsPath(path)) {
      return {
        channel: 'uk:desktop',
        platform: 'react_cgc',
      }
    }
    if (isRoomCreatorPath(path) || isGetTheLookPath(path)) {
      return {
        channel: 'uk:desktop',
        platform: 'react',
      }
    }
    return null
  }

  getTemplateTitle() {
    const path = this.location
    if (isReviewsPath(path) && !isReviewsArgosCard(path)) return 'react_cgc'
    if (isReviewsArgosCard(path)) return 'react_cgc_argos_card'
    if (isHomepagePath(path)) return 'home'
    if (isMagnoliaApp(path)) return 'ui-cms'
    if (isRoomCreatorPath(path)) return 'room-creator'
    if (isGetTheLookPath(path)) return 'get-the-look'
    return 'error-page'
  }

  getTrackingEventName(pathName) {
    if (isHomepagePath(pathName)) return 'hp_initialLoad'
    if (isReviewsPath(pathName)) return 'cgc_initialLoad'
    return 'ui-cms'
  }

  getUserObject() {
    let loginStatus = ''
    if (this.userInfo.userState === 'GUEST') {
      loginStatus = 'Non-Registered'
    } else if (this.userInfo.userState === 'RECOGNISED') {
      loginStatus = 'Recognised'
    } else if (this.userInfo.userState === 'LOGGEDIN') {
      loginStatus = 'Registered'
    }

    const userId = this.userInfo && this.userInfo.userId ? this.userInfo.userId : ''

    return [
      {
        profile: [
          {
            profileInfo: {
              profileID: userId,
            },
            attributes: {
              globalView:
                document.cookie.indexOf('PostCodeSessionCookie') < 0 || getCookie('PostCodeSessionCookie') === '%2C%2C',
              loginStatus,
            },
          },
        ],
      },
    ]
  }

  serialize() {
    return serialize(this.getDataObject())
  }
}

const getSatelliteTrackingEvent = (eventName) => {
  // console.log(`Firing _satellite.track for event '${eventName}'`)
  if (window.digitalData.track && eventName) window.digitalData.track(eventName)
}

const pushEventAndTrack = (event, removeEventWrapper = false) => {
  if (typeof window.digitalData !== 'undefined' && !!window.digitalData.track) {
    window.digitalData.event = (window.digitalData.event && window.digitalData.event) || []
    const events = window.digitalData.event
    if (removeEventWrapper) {
      events.push({ ...event })
    } else {
      events.push({ event })
    }
    if (event && event.eventInfo) getSatelliteTrackingEvent(event.eventInfo.eventName)
  }
}

const pushRecommendation = (rec) => {
  ensureStructure(window)
  const {
    digitalData: {
      page: {
        attributes: { recommendations },
      },
    },
  } = window

  recommendations.push(rec)
}

export default TaggingSerializer
export { pushEventAndTrack, pushRecommendation, getSatelliteTrackingEvent }
