import React, { Component } from 'react'
import { Route, Switch } from 'react-router'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Loadable from 'react-loadable'
import { LoadingBar } from '@sainsburys-tech/bolt'

import { loadApp } from '../actions/appActions'
import { isHabitat } from '../helpers/brandHelper'
import TaggingSerializer, { getSatelliteTrackingEvent } from '../helpers/TaggingSerializer'
import MainLayout from '../layouts/MainLayout'
import { ReviewsContainer, CmsPageContainer, ErrorPageContainer, HullabalookContainer } from '../containers'
import { getMeta, reviewsRoute, homepageRoute, cmsPageRoute, roomCreatorRoute, getTheLookRoute } from '../routes'
import { isMagnoliaApp, isHomepagePath, getHabitatCanonicalPath } from '../helpers/pathCheck'

import AppGlobalStyles from './App.styles'

const DatePicker =
  process.env.PREVIEW_MODE && process.env.PREVIEW_MODE === 'true'
    ? Loadable({
        loader: () => import(/* webpackChunkName: "datepicker" */ '../components/DatePicker/DatePicker'),
        loading: () => null,
        modules: ['datepicker'],
      })
    : null

export class App extends Component {
  updateDigitalDataObject = (userInfo) => {
    const { cmsData } = this.props
    const pathName = window?.location?.pathname
    const cmsTemplate = cmsData && cmsData.template
    const canonicalPathName = getHabitatCanonicalPath(pathName)
    const taggingSerializer = new TaggingSerializer(canonicalPathName, userInfo, cmsTemplate)
    if (window.digitalData) {
      Object.assign(window.digitalData, taggingSerializer.getDataObject())
      const eventName = taggingSerializer.getTrackingEventName(canonicalPathName)
      getSatelliteTrackingEvent(eventName)
    }
  }

  componentDidMount() {
    const { loadApp: loadApplication } = this.props
    loadApplication()
    if (isHabitat()) {
      this.updateDigitalDataObject()
    }
  }

  componentDidUpdate(prevProps) {
    const { history, cmsMeta, homepageMeta } = this.props
    const {
      location: { pathname },
    } = history
    if (prevProps && prevProps.meta === undefined) {
      const metaList = document.getElementsByTagName('META')
      const validMetaDesc = metaList && metaList[3] && metaList[3].name
      if (isMagnoliaApp(pathname) && cmsMeta) {
        global.document.title = cmsMeta.title
        validMetaDesc && metaList[3].setAttribute('content', cmsMeta.description)
      } else if (isHomepagePath(pathname) && homepageMeta) {
        global.document.title = homepageMeta.title
        validMetaDesc && metaList[3].setAttribute('content', homepageMeta.description)
      } else {
        // set default meta from route
        global.document.title = getMeta(pathname).title
        validMetaDesc && metaList[3].setAttribute('content', getMeta(pathname).description)
      }
    }
  }

  render() {
    const { history, isLoading } = this.props
    const homepageContainer = CmsPageContainer
    const hullabalookContainer = isHabitat() ? HullabalookContainer : ErrorPageContainer

    return (
      <MainLayout history={history}>
        <AppGlobalStyles />
        {DatePicker && isMagnoliaApp(history.location.pathname) && <DatePicker />}
        <LoadingBar loading={isLoading} loadingColor='secondary' />
        <Switch>
          <Route exact path={homepageRoute.path} component={homepageContainer} />
          <Route path={reviewsRoute.path} component={ReviewsContainer} />
          <Route path={cmsPageRoute.path} component={CmsPageContainer} />
          <Route path={roomCreatorRoute.path} component={hullabalookContainer} />
          <Route path={getTheLookRoute.path} component={hullabalookContainer} />
          <Route component={ErrorPageContainer} />
        </Switch>
      </MainLayout>
    )
  }
}

App.propTypes = {
  isLoading: PropTypes.bool,
  loadApp: PropTypes.func.isRequired,
  history: PropTypes.shape({
    location: PropTypes.string,
    pathname: PropTypes.string,
  }),
  cmsMeta: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  homepageMeta: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
}

App.defaultProps = {
  isLoading: true,
  history: {
    location: '',
    pathname: '',
  },
  cmsMeta: {
    title: '',
    description: '',
  },
  homepageMeta: {
    title: '',
    description: '',
  },
}

const mapStateToProps = (state) => {
  const isLoading = state.homepageApp.isLoading || state.cmsApp.isLoading || state.reviewsApp.isLoading
  return {
    cmsData: state.cmsApp?.cmsData,
    cmsMeta: state.cmsApp?.cmsData?.meta,
    homepageMeta: state.homepageApp?.homepageData?.meta,
    isLoading,
  }
}

export default withRouter(connect(mapStateToProps, { loadApp })(App))
